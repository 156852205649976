<template>
    <div class="company-license-list-page">
        <section>
            <h3 v-if="!isLoading">Gebruikerslijst voor {{ companyInfo.Name }}</h3>
            <h3 v-else>Gebruikerslijst</h3>
            <div class="buttons">
                <button v-on:click="startCsvExport()" class="btn btn-primary" :disabled="isLoading">Exporteer naar CSV</button>
            </div>
            <table class="table table-sm buttoned-table">
                <thead>
                <tr>
                    <th scope="col">Gebruikersnaam</th>
                    <th scope="col">E-mailadres</th>
                    <th scope="col">Registratiedatum</th>
                    <th scope="col">Laatste speeldatum</th>
                    <th scope="col">Gespeeld</th>
                    <th scope="col" v-if="showLicenses">Licentiecode</th>
                    <th scope="col">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in userDataList">
                    <td>{{ item.Username }}</td>
                    <td>
                        <span v-if="item.Email">{{ item.Email }}</span><span v-else><em>Geen e-mailadres bekend</em></span>
                    </td>
                    <td>{{ formatDate(item.CreatedOn) }}</td>
                    <td>{{ formatDate(item.LastPlayedOn) }}</td>
                    <td>{{ item.PlayedCount }}</td>
                    <td v-if="showLicenses">{{ item.LicenseCode || item.LicenseID }}</td>
                    <td class="button-column">
                        <button v-on:click="confirmRemoveLicense(item.UserID)" class="btn btn-danger">Verwijder licentie</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </section>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Company, CompanyUserList, UserData, WithDocumentId} from "@/types/types";
import {getCompany, getCompanyCodeMapping, getCompanyUserList, getUserData} from "@/service/dbService";
import {detachLicense} from "@/service/functionService";
import {CompanyCodeMapping} from "@functions/shared/types";
import {csvExport, CsvExportHeader, CsvExportRequest} from "@/utils/csv";

type DisplayedUser = {
    UserID: string;
    Username: string;
    Email?: string;
    PlayedCount: number;
    LastPlayedOn: number;
    CreatedOn: number;
    LicenseID: string;
    LicenseCode?: string;
}

const csvExportPrep: CsvExportRequest = {
    Headers: [
        {
            CsvName: "Gebruikersnaam",
            FieldName: "Username",
        },
        {
            CsvName: "Email",
            FieldName: "Email",
            DefaultValue: "",
        },
        {
            CsvName: "Gespeeld",
            FieldName: "PlayedCount",
        },
        {
            CsvName: "Licentiecode",
            FieldName: "LicenseCode",
            DefaultValue: "",
        },
    ]
};

@Component({
    name: "CompanyUserListPage"
})
export default class CompanyUserListPage extends Vue {
    isLoading: boolean = false;

    @Prop({type: String})
    companyId!: string;

    companyInfo: Partial<Company> = {};
    userList: CompanyUserList[] = [];
    userDataList: DisplayedUser[] = [];

    showLicenses: boolean = true;

    async mounted() {
        await this.refresh();
    }

    async refresh(): Promise<void> {
        this.isLoading = true;
        this.userDataList = [];
        let getCompanyPromise = getCompany(this.companyId);
        let getCompanyUserListPromise = getCompanyUserList(this.companyId);
        this.companyInfo = await getCompanyPromise ?? {};
        this.userList = await getCompanyUserListPromise;
        console.info("Got userlist: %o, querying users...", this.userList);

        let promises: Promise<WithDocumentId<UserData> | null>[] = [];
        this.userList.forEach(ul => {
            promises.push(getUserData(ul.UserID));
        });

        let results = await Promise.all(promises);
        console.info("Got user results: %o", results);

        // Make the user display list.
        let licenseIdsToFetch: {[key: string]: DisplayedUser[]} = {};
        let newUserDataList: DisplayedUser[] = [];
        results.forEach(item => {
            if (item) {
                let userData = this.userList.find(u => item.id === u.UserID);
                let displayedUser: DisplayedUser = {
                    UserID: item.id as string,
                    Username: item.Username,
                    PlayedCount: item.PlayedCount || 0,
                    CreatedOn: item.CreatedOn || 0,
                    LastPlayedOn: item.LastPlayedOn || 0,
                    LicenseID: userData?.CodeID || "Onbekend",
                };
                if (item.Email) {
                    displayedUser.Email = item.Email;
                }
                if (userData) {
                    licenseIdsToFetch[userData.CodeID] = licenseIdsToFetch[userData.CodeID] || [];
                    licenseIdsToFetch[userData.CodeID].push(displayedUser);
                }
                newUserDataList.push(displayedUser);
            }
        });

        // Fetch licenses. We'll do these one per request, but all at once.
        let licenseKeys = Object.keys(licenseIdsToFetch);

        let codeMappingPromises: Promise<WithDocumentId<CompanyCodeMapping>|null>[] = [];
        licenseKeys.forEach(licenseKey => {
            codeMappingPromises.push(getCompanyCodeMapping(licenseKey));
        });
        let codeMappingResults = await Promise.all(codeMappingPromises);
        // Go over results and add back to the users.
        codeMappingResults.forEach(r => {
            if (r && r.id) {
                licenseIdsToFetch[r.id].forEach(user => {
                    user.LicenseCode = r.Code;
                })
            }
        });

        // Finally done...
        this.userDataList = newUserDataList;

        this.isLoading = false;
    }

    formatDate(timestamp: number): string {
        if(timestamp === 0) {
            return "Onbekend";
        }
        let date = new Date(timestamp * 1000);
        return date.toLocaleString("nl-NL");
    }

    async confirmRemoveLicense(userId: string) {
        if (confirm("Verwijder deze gebruiker?")) {
            await this.doRemoveLicense(userId);
        }
    }

    async doRemoveLicense(userId: string) {
        let result = await detachLicense({
            RemovalType: "REMOVE_USER_LICENSE_ID",
            CompanyID: this.companyId,
            LimitUserID: userId,
            Reclaim: true,
        });
        alert(result.message);
        await this.refresh();
    }

    startCsvExport() {
        let csv = csvExport(csvExportPrep, this.userDataList);
        const companyName = this.companyInfo.Name || 'onbekend bedrijf';
        let filename = `Gebruikerslijst voor ${companyName}.csv`;

        let csvBlob = new Blob([csv], {type: "application/octet-stream;charset=utf-8"});
        let csvUrl = URL.createObjectURL(csvBlob);
        let a = document.createElement("a");
        a.setAttribute("href", csvUrl);
        a.setAttribute("download", filename);
        a.style.visibility = "hidden";
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            URL.revokeObjectURL(csvUrl);
        }, 100);
    }
}
</script>

<style lang="scss" scoped>
</style>
