import {initializeApp} from "firebase/app"
import {Auth, browserLocalPersistence, getAuth} from "firebase/auth"
import {getStorage, FirebaseStorage} from "firebase/storage";
import {Firestore, getFirestore} from "firebase/firestore";
import {firebaseConfig, firebaseMeta} from "../config/firebaseconfig.COND_FIREBASE";
import {Functions, getFunctions, connectFunctionsEmulator} from 'firebase/functions';

export const firebaseApp = initializeApp(firebaseConfig, {
    name: "Authoring Tool",
    automaticDataCollectionEnabled: false,
});

export const firebaseStorage: FirebaseStorage = getStorage(firebaseApp);
export const firestore: Firestore = getFirestore(firebaseApp);
export const firebaseAuth: Auth = getAuth(firebaseApp);
firebaseAuth.setPersistence(browserLocalPersistence);
export const firebaseFunctions: Functions = getFunctions(firebaseApp, firebaseMeta.functionsRegion);
if (firebaseMeta.useFunctionsEmulator) {
    let parts = firebaseMeta.useFunctionsEmulator.split(":");
    if (parts.length !== 2) {
        throw new Error("Invalid functions emulator configuration.");
    }
    connectFunctionsEmulator(firebaseFunctions, parts[0], parseInt(parts[1]));
}

// Export the app, Firestore and  to the window as well. This is for debugging purposes only.
// Enable this when required.
(window as any).firebaseApp = firebaseApp;
(window as any).firebaseStorage = firebaseStorage;
(window as any).firebaseAuth = firebaseAuth;
(window as any).firestore = firestore;
