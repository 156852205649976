var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "help-page" }, [
      _c("h1", [_vm._v("Help")]),
      _vm._v(" "),
      _c("section", [
        _c("h3", [_vm._v("Publicatievolgorde")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Volgorde publicatie: om een nieuwe serie te publiceren kun je het beste deze volgorde aanhouden:"
          ),
        ]),
        _vm._v(" "),
        _c("ol", [
          _c("li", [_vm._v("Serie aanmaken via pagina")]),
          _vm._v(" "),
          _c("li", [_vm._v("Aflevering aanmaken via pagina")]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Aflevering in elkaar zetten via de authoringtool die direct erna verschijnt"
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v("Aflevering op gepubliceerd zetten via de publish knop"),
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("Eventueel meer afleveringen maken + publiceren")]),
          _vm._v(" "),
          _c("li", [_vm._v("Serie publiceren via de serie bewerken pagina")]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("h3", [_vm._v("Tabjes in de authoringtool")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "In de authoringtool zijn drie tabs: Authoring, Files en Meta."
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("In de authoring tab kun je de aflevering knippen.")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "In de files tab kun je bestanden uploaden, afspelen en verwijderen."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Op de meta tab kun je gegevens zoals de titel en beschrijving aanpassen. Ook is er een knop om de\n            aflevering uit publicatie te halen, al kunnen mensen die de app open hebben deze nog altijd beluisteren.\n            Pas op: deze wijzigingen worden direct doorgevoerd als de aflevering al op zichtbaar staat."
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("h3", [_vm._v("Aflevering knippen")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Bij het aanpassen van de aflevering zijn er wat dingen om rekening mee te houden:"
          ),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v("Alle afleveringen moeten een begin en een einde hebben."),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Alle paden moeten uiteindelijk bij het einde uitkomen, al mag dat wel vanaf verschillende plekken.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              'Je mag niet meer dan 1 connectie maken per uiteinde van een item. De "To" optie moet altijd naar\n                exact 1 ander item wijzen.\n            '
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "Bij het invullen van keuzewoorden is de volgende informatie van toepassing:"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("Keuzewoorden")]),
        _vm._v(" "),
        _c("div", [
          _c("p", [
            _vm._v(
              "Een keuze mag meerdere woorden hebben om spraak detectie te verbeteren, gesplitst met een comma en\n                (optioneel) spaties."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "De app laat het eerste woord zien, de rest wordt alleen gebruikt voor spraak detectie."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "De spraakdetectie is niet hoofdletter gevoelig, maar hoofdletters maken wel uit voor de gebruiker,\n                het eerste woord moet dus hoofdletters hebben als je hem met hoofdletters wilt tonen in de app"
            ),
          ]),
          _vm._v(" "),
          _c("section", [
            _c("h5", [_vm._v("Voorbeelden")]),
            _vm._v(" "),
            _c("p", [_vm._v("Dit mag:")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", { staticStyle: { "font-family": "monospace" } }, [
                _vm._v("Jazz,jas,yes,jus"),
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { "font-family": "monospace" } }, [
                _vm._v("Jazz, jas, yes, jus"),
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { "font-family": "monospace" } }, [
                _vm._v("Jazz , jas , yes , jus"),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Dit mag NIET:")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", { staticStyle: { "font-family": "monospace" } }, [
                _vm._v("Jazz,jas,yes,"),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("jus,")]),
                _vm._v(" —\n                        "),
                _c("em", [_vm._v("Comma aan het einde van de lijst")]),
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { "font-family": "monospace" } }, [
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("Jazz muziek"),
                ]),
                _vm._v(", jas, yes, jus\n                        — "),
                _c("em", [_vm._v("Meerdere woorden zonder comma er tussen")]),
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { "font-family": "monospace" } }, [
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("Jazz jas"),
                ]),
                _vm._v(", yes, jus —\n                        "),
                _c("em", [_vm._v("Missende comma tussen woorden")]),
              ]),
              _vm._v(" "),
              _c("li", { staticStyle: { "font-family": "monospace" } }, [
                _vm._v("Jazz"),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v(",,")]),
                _vm._v(" yes, jus — "),
                _c("em", [
                  _vm._v(
                    "Missend\n                        woord tussen de commas"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", [
        _c("h3", [_vm._v("Knopjes in de authoringtool")]),
        _vm._v(" "),
        _c("p", [_vm._v("Er staan drie knoppen in de authoringtool.")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            'De "Save" knop slaat de aflevering intern op, maar publiceert deze niet in de app. Hiermee kun je intern\n            ontwikkelen, of de aflevering vast maken zonder deze zichtbaar te maken.'
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            'De "Publish" knop publiceert de aflevering. Als de serie waar deze aflevering in zit ook gepubliceerd is,\n            dan is de aflevering meteen zichtbaar (gebruikers moeten wel de app restarten).'
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }