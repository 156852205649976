var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        fullscreen: this.isFullscreen,
        "not-fullscreen": !this.isFullscreen,
      },
      attrs: { id: "app" },
    },
    [
      !this.isFullscreen
        ? _c("div", { staticClass: "container-xxl" }, [
            this.hasMenuBar
              ? _c("nav", { staticClass: "navbar", attrs: { id: "menubar" } }, [
                  _c("div", { staticClass: "container-fluid d-flex" }, [
                    _c(
                      "div",
                      { staticClass: "navbar-brand align-self-start" },
                      [_vm._v(_vm._s(_vm.appBranding.name))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "align-self-end" }, [
                      true
                        ? _c("ul", { staticClass: "navbar-nav flex-row" }, [
                            _c(
                              "li",
                              { staticClass: "nav-item p-2" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link",
                                    attrs: { to: "/companies" },
                                  },
                                  [
                                    _c("i", { staticClass: "bi bi-card-list" }),
                                    _vm._v(
                                      " Bedrijven\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "nav-item p-2" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "nav-link",
                                    attrs: { to: "/logout" },
                                  },
                                  [
                                    _c("i", { staticClass: "bi bi-people" }),
                                    _vm._v(
                                      " Uitloggen: " +
                                        _vm._s(_vm.userDisplayName) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("main", [_c("router-view")], 1),
          ])
        : _c("main", [_c("router-view")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }