import "./lib.ts";
import App from "@/general/components/App.vue";
import {router} from "@/router";

// Make the root app along with the router set up in router.ts.
export const appVue = new App({
    router: router
}).$mount("#app-render-root");

// For debugging and devtool support. Can be removed before production.
(window as any).appVue = appVue;
