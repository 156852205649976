<template>
    <div class="company-delete-page">
        <section>
            <h3>Bedrijf verwijderen</h3>
            <p>Vanaf deze pagina is het mogelijk om een bedrijf te verwijderen. Let op: na het verwijderen van het bedrijf zullen alle gebruikers hun toegang direct verliezen.</p>
            <div>
                <div v-if="isLoading">
                    <div>
                        <div class="spinner-border" role="status">
                        </div>
                        Gegevens worden opgehaald.
                    </div>
                </div>
                <div v-else-if="isDeleting">
                    <div>
                        <div class="spinner-border" role="status">
                        </div>
                        Gegevens worden verwijderd.
                    </div>
                </div>
                <div v-else>
                    <form v-on:submit.prevent="confirmDeleteCompany" style="margin-top: 30px">
                        <div class="form-item mb-3">
                            <label for="companyNumUsersEl">Aantal gebruikers</label>
                            <input id="companyNumUsersEl" v-model="fetchedUserMessage" type="text" name="num_users" readonly
                                   class="form-control disabled">
                        </div>
                        <div class="form-item form-check mb-3">
                            <input type="checkbox" class="form-check-input" id="companyAllowDelete" value="1" v-model="companyAllowDelete" name="allow_delete">
                            <label class="form-check-label" for="companyAllowDelete">Bevestig verwijderen</label>
                        </div>

                        <button type="submit" class="btn btn-danger" :disabled="!companyAllowDelete">Bevestig verwijderen</button>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Company} from "@/types/types";
import {deleteCompany, deleteCompanyCodeMappingByCompanyId, getCompany, getCompanyUserList} from "@/service/dbService";
import {detachLicense} from "@/service/functionService";

@Component({
    name: "CompanyDeletePage"
})
export default class CompanyDeletePage extends Vue {
    companyInfo: Partial<Company> = {};
    isLoading: boolean = false;
    isDeleting: boolean = false;

    @Prop({type: String})
    companyId!: string;

    companyAllowDelete: boolean = false;
    fetchedUserMessage: string = "";
    fetchedUserCount: number = 99999;

    async mounted() {
        await this.refresh();
    }

    async refresh(): Promise<void> {
        this.isLoading = true;
        this.isDeleting = false;

        let companyDoc = await getCompany(this.companyId);
        if (!companyDoc) {
            throw new Error("Failed to retrieve company data.");
        }

        this.companyInfo = companyDoc;

        let companyUserList = await getCompanyUserList(this.companyId);

        this.fetchedUserCount = companyUserList.length;
        this.fetchedUserMessage = this.messageFor(companyUserList.length);

        this.isLoading = false;
    }

    messageFor(users: number): string {
        if (users < 0) {
            return "Er is iets fout gegaan.";
        } else if (users === 0) {
            return "Er zijn geen gebruikers onder dit bedrijf gevonden.";
        } else {
            return `Dit bedrijf heeft ${users} gebruikers.`;
        }
    }

    async confirmDeleteCompany() {
        this.isDeleting = true;

        if(this.fetchedUserCount > 0) {
            let results = await detachLicense({
                RemovalType: "REMOVE_COMPANY_ID",
                CompanyID: this.companyId,
                Reclaim: false,
            });
            if (results.systemCode !== "COMPANY_DETACH_OK") {
                alert("Er is iets fout gegaan tijdens het opschonen van de licenties.");
                return;
            }
        }

        await deleteCompanyCodeMappingByCompanyId(this.companyId);
        await deleteCompany(this.companyId);
        alert("Het bedrijf is verwijderd.");
        await this.$router.replace('/companies');
        this.isDeleting = false;
    }
}
</script>

<style lang="scss" scoped>
</style>
