var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "license-edit-page" }, [
    _c("section", [
      _vm.isNewLicense
        ? _c("h3", [
            _vm._v("Licentie toevoegen: " + _vm._s(_vm.companyInfo.Name)),
          ])
        : _c("h3", [
            _vm._v("Licentie bewerken: " + _vm._s(_vm.companyInfo.Name)),
          ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.saveLicense.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "form-item mb-3" }, [
              _c("label", { attrs: { for: "licenseEditCodeEl" } }, [
                _vm._v("Code"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.licenseInfo.Code,
                    expression: "licenseInfo.Code",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "licenseEditCodeEl",
                  type: "text",
                  name: "license_code",
                },
                domProps: { value: _vm.licenseInfo.Code },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.licenseInfo, "Code", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-item mb-3" }, [
              _c("label", { attrs: { for: "licenseEditUsesEl" } }, [
                _vm._v("Resterend/totaal"),
              ]),
              _vm._v(" "),
              !_vm.isNewLicense
                ? _c("div", { staticStyle: { display: "inline-block" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.licenseInfo.Uses,
                          expression: "licenseInfo.Uses",
                        },
                      ],
                      attrs: {
                        id: "licenseEditUsesEl",
                        type: "number",
                        min: "0",
                        name: "license_uses",
                      },
                      domProps: { value: _vm.licenseInfo.Uses },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.licenseInfo, "Uses", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" /\n                    "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.licenseInfo.UsesTotal,
                    expression: "licenseInfo.UsesTotal",
                  },
                ],
                attrs: {
                  id: "licenseEditUsesTotalEl",
                  type: "number",
                  min: "0",
                  name: "license_usestotal",
                },
                domProps: { value: _vm.licenseInfo.UsesTotal },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.licenseInfo, "UsesTotal", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-item mb-3" }, [
              _c("label", [
                _vm._v("Geldigheidsduur registratie nieuwe gebruikers"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.licenseInfo.RegistrationIsTimeLimited,
                      expression: "licenseInfo.RegistrationIsTimeLimited",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "checkbox",
                    name: "license_registration_until_type",
                    id: "licenseRegistrationUntilTypeLimitedEl",
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.licenseInfo.RegistrationIsTimeLimited
                    )
                      ? _vm._i(
                          _vm.licenseInfo.RegistrationIsTimeLimited,
                          null
                        ) > -1
                      : _vm.licenseInfo.RegistrationIsTimeLimited,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.licenseInfo.RegistrationIsTimeLimited,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.licenseInfo,
                              "RegistrationIsTimeLimited",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.licenseInfo,
                              "RegistrationIsTimeLimited",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.licenseInfo,
                          "RegistrationIsTimeLimited",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form-label-check",
                    attrs: { for: "licenseRegistrationUntilTypeLimitedEl" },
                  },
                  [
                    _vm._v(
                      "\n                            Registratiebeperking aan. Registratie mogelijk tot en met:\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { display: "inline-block" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.registrationTimeLimit,
                        expression: "registrationTimeLimit",
                      },
                    ],
                    attrs: {
                      type: "date",
                      disabled: !_vm.licenseInfo.RegistrationIsTimeLimited,
                    },
                    domProps: { value: _vm.registrationTimeLimit },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.registrationTimeLimit = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-item mb-3" }, [
              _c("label", [_vm._v("Geldigheidsduur alle gebruikers")]),
              _vm._v(" "),
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.licenseInfo.UsageIsTimeLimited,
                      expression: "licenseInfo.UsageIsTimeLimited",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "checkbox",
                    name: "license_usage_until_type",
                    id: "licenseUsageUntilTypeLimitedEl",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.licenseInfo.UsageIsTimeLimited)
                      ? _vm._i(_vm.licenseInfo.UsageIsTimeLimited, null) > -1
                      : _vm.licenseInfo.UsageIsTimeLimited,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.licenseInfo.UsageIsTimeLimited,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.licenseInfo,
                              "UsageIsTimeLimited",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.licenseInfo,
                              "UsageIsTimeLimited",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.licenseInfo, "UsageIsTimeLimited", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form-label-check",
                    attrs: { for: "licenseUsageUntilTypeLimitedEl" },
                  },
                  [
                    _vm._v(
                      "\n                            Gebruiksbeperking aan. Gebruik mogelijk tot en met:\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { display: "inline-block" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.usageTimeLimit,
                        expression: "usageTimeLimit",
                      },
                    ],
                    attrs: {
                      type: "date",
                      disabled: !_vm.licenseInfo.UsageIsTimeLimited,
                    },
                    domProps: { value: _vm.usageTimeLimit },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.usageTimeLimit = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveLicense.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        Opslaan\n                    "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            !_vm.isNewLicense
              ? _c(
                  "form",
                  {
                    staticStyle: { "margin-top": "30px" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteLicense.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("Verwijder licentie")]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }