var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "claims-page" }, [
    _vm.error !== null
      ? _c("p", { staticClass: "alert alert-warning" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.sending ? _c("p", [_vm._v("Activating")]) : _vm._e(),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Dit is een debug pagina om de user claims handmatig aan te kunnen passen."
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Gebruik de Firebase Console om de gebruiker aan te maken. Daarna kun je dit formulier gebruiken om\n        administrator rechten toe te kennen."
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-item mb-3" }, [
      _c("label", { attrs: { for: "manageUserClaimsUid" } }, [
        _vm._v("Email of UID"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.userUid,
            expression: "userUid",
          },
        ],
        staticClass: "form-control",
        attrs: {
          type: "text",
          name: "manage_user_claims_uid",
          id: "manageUserClaimsUid",
        },
        domProps: { value: _vm.userUid },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.userUid = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-item mb-3" }, [
      _c("label", { attrs: { for: "manageUserClaimsAction" } }, [
        _vm._v("Action"),
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.action,
              expression: "action",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            name: "manage_user_claims_action",
            id: "manageUserClaimsAction",
          },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.action = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        [
          _c("option", { attrs: { value: "GET_CLAIMS" } }, [
            _vm._v("Get Claims"),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "SET_CLAIMS" } }, [
            _vm._v("Set Claims To"),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "UPDATE_CLAIMS" } }, [
            _vm._v("Update Specified Claims"),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "REMOVE_CLAIMS" } }, [
            _vm._v("Remove Specified Claims"),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "GIVE_ADMIN_RIGHTS" } }, [
            _vm._v("Give Admin Rights"),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-item mb-3" }, [
      _c("label", { attrs: { for: "manageUserClaimsTextarea" } }, [
        _vm._v("Textarea"),
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.textarea,
            expression: "textarea",
          },
        ],
        staticClass: "form-control",
        staticStyle: { "font-family": "monospace" },
        attrs: {
          name: "manageUserClaimsTextarea",
          id: "manageUserClaimsTextarea",
          cols: "30",
          rows: "5",
        },
        domProps: { value: _vm.textarea },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.textarea = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-item mb-3" }, [
      _c(
        "button",
        {
          attrs: { disabled: _vm.sendButtonBlocked },
          on: {
            click: function ($event) {
              return _vm.manageUserClaimsActivate()
            },
          },
        },
        [_vm._v("Call Function")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-item mb-3" }, [
      _c("label", { attrs: { for: "manageUserClaimsTextarea" } }, [
        _vm._v("Resultaat"),
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.resultArea,
            expression: "resultArea",
          },
        ],
        staticClass: "form-control disabled",
        staticStyle: { "font-family": "monospace" },
        attrs: {
          name: "manageUserClaimsTextarea",
          id: "manageUserClaimsResultArea",
          cols: "30",
          rows: "5",
          readonly: "",
        },
        domProps: { value: _vm.resultArea },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.resultArea = $event.target.value
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }