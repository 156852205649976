import {firebaseAuth} from "@/globals";
import * as auth from "firebase/auth";

export type LoginState = {
    loggedIn: boolean;
    loginUser: auth.User|null;
};

type LoginWaiter = {
    onComplete: (user: auth.User|null) => void;
    onError: (error: Error) => void;
};

// Did we register onAuthStateChanged?
let hookRegistered = false;
// Was onAuthStateChanged ever called with a definitive status update?
let hookCalled = false;
// Unsubscribe function. We don't really use this.
let hookUnsubscribe: ReturnType<typeof auth.onAuthStateChanged>;
// When onAuthStateChanged is finished, immediately update these.
let loginDataWaiters: LoginWaiter[] = [];

// Last user.
let currentUser: auth.User|null;

function firebaseOnAuthStateChanged(user: auth.User|null): void {
    currentUser = user;
    hookCalled = true;
    loginDataWaiters.forEach(p => {
        p.onComplete(user);
    });
    loginDataWaiters = [];
}

function firebaseOnAuthStateError(error: Error): void {
    loginDataWaiters.forEach(p => {
        p.onError(error);
    });
    loginDataWaiters = [];
}

// Ensures the hook is registered. If true, synchronous data is available and reliable.
function ensureHookIsRegistered(): boolean {
    if (!hookRegistered) {
        hookRegistered = true;
        hookUnsubscribe = auth.onAuthStateChanged(firebaseAuth, firebaseOnAuthStateChanged, firebaseOnAuthStateError);
        return false;
    }
    return hookCalled;
}

// Get the login state known to the app. If "null", the state is indeterminate and you must use getLoginState.
export function getKnownLoginState(): LoginState|null {
    return hookCalled ? {
        loggedIn: currentUser !== null,
        loginUser: currentUser,
    } : null;
}

// Return a determinate login state. If the promise resolves with an error, there is no way to know the login state
// at the moment.
export async function getLoginState(): Promise<LoginState> {
    // If we already have the data, return it immediately.
    if (ensureHookIsRegistered()) {
        return {
            loggedIn: currentUser !== null,
            loginUser: currentUser,
        }
    }
    else {
        // Add a new promises.
        return new Promise<LoginState>((resolve, reject) => {
            loginDataWaiters.push({
                onComplete: (user) => {
                    resolve({
                        loggedIn: user !== null,
                        loginUser: user,

                    });
                },
                onError: (error) => {
                    reject(error);
                }
            });
        });
    }
}

export async function logOutCurrentUser(): Promise<void> {
    currentUser = null;
    hookCalled = false;
    return auth.signOut(firebaseAuth);
}
