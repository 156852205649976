<template>
    <h1>Pagina niet gevonden (404).</h1>

</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component({
    name: "NotFoundPage"
})
export default class NotFoundPage extends Vue {
}
</script>
