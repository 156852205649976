<template>
    <div class="companies-page">
        <section>
            <h3>Bedrijven</h3>
            <router-link to="/companies/new" custom v-slot="{navigate}" class="btn btn-primary">
                <button @click="navigate" @keypress.enter="navigate" role="link">Nieuw bedrijf toevoegen</button>
            </router-link>
            <table class="table table-sm buttoned-table">
                <thead>
                <tr>
                    <th scope="col">Naam</th>
                    <th scope="col">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in displayedCompanies">
                    <td>{{ item.Name }}</td>
                    <td class="button-column">
                        <router-link :to="`/companies/${item.id}/edit`" class="btn btn-link">Bewerken</router-link>
                        <router-link :to="`/companies/${item.id}/licenses`" class="btn btn-link">Licenties</router-link>
                        <router-link :to="`/companies/${item.id}/users`" class="btn btn-link">Gebruikers</router-link>
                    </td>
                </tr>
                </tbody>
            </table>
        </section>
    </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {Company, WithDocumentId} from "@/types/types";
import {getAllCompanies} from "@/service/dbService";

@Component({
    name: "CompanyListPage"
})
export default class CompanyListPage extends Vue {
    displayedCompanies: WithDocumentId<Company>[] = [];
    isLoading: boolean = false;

    async mounted() {
        await this.refresh();
    }

    async refresh(): Promise<void> {
        this.isLoading = true;
        this.displayedCompanies = await getAllCompanies();
        this.isLoading = false;
    }
}
</script>
