// Basic CSV exporter

export type CsvExportHeader = {
    CsvName: string;
    FieldName: string;
    FieldPreProcessor?: (data: string) => string;
    DefaultValue?: string;
};

export type CsvExportRequest = {
    Headers: CsvExportHeader[];
};

const LINEENDING = "\r\n";

// CSV doubles up on double quotes to escape them.
function escapeDoubleQuotes(text: string) {
    return text.replace(/"/g, '""');
}

const defaultPreprocessor = ((d: string) => d);

export function csvExport(meta: CsvExportRequest, data: any[]): string {
    let csv = "";
    for (let i = 0; i < meta.Headers.length; ++i) {
        csv += `${i != 0 ? ',' : ''}${meta.Headers[i].CsvName}`;
    }
    csv += LINEENDING;

    for (let i = 0; i < data.length; ++i) {
        let row = "";

        for (let j = 0; j < meta.Headers.length; ++j) {
            let header = meta.Headers[j];
            let proc = header.FieldPreProcessor || defaultPreprocessor;
            let fieldValue = data[i][header.FieldName];
            if (typeof fieldValue === "number") {
                fieldValue = fieldValue.toString();
            }
            if (typeof fieldValue != "string" && typeof header.DefaultValue !== "undefined") {
                fieldValue = header.DefaultValue;
            }
            // If it's still not...
            if (typeof fieldValue != "string") {
                console.error("Field value for %s is not string: %o %o", header.FieldName, fieldValue, data[i]);
            }
            fieldValue = proc(fieldValue);
            row += `${j != 0 ? ',' : ''}"${escapeDoubleQuotes(fieldValue)}"`;
        }

        csv += row + LINEENDING;
    }

    return csv;
}
