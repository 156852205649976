var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-license-list-page" }, [
    _c(
      "section",
      [
        _c("h3", [_vm._v("Licenties")]),
        _vm._v(" "),
        _c("router-link", {
          staticClass: "btn btn-primary",
          attrs: {
            to: "/companies/" + _vm.companyId + "/licenses/new",
            custom: "",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var navigate = ref.navigate
                return [
                  _c(
                    "button",
                    {
                      attrs: { role: "link" },
                      on: {
                        click: navigate,
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return navigate.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Nieuwe licentie toevoegen")]
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("table", { staticClass: "table buttoned-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.displayedLicenses, function (item) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(item.Code))]),
                _vm._v(" "),
                _c("td", { class: { "text-warning": item.Uses <= 0 } }, [
                  _vm._v(_vm._s(item.Uses) + " / " + _vm._s(item.UsesTotal)),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "button-column" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-link",
                        attrs: {
                          to:
                            "/companies/" +
                            _vm.companyId +
                            "/licenses/" +
                            item.id,
                        },
                      },
                      [_vm._v("Bewerken")]
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Code")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Resterend/Totaal")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }