<template>
    <div class="company-license-list-page">
        <section>
            <h3>Licenties</h3>
            <router-link :to="`/companies/${companyId}/licenses/new`" custom v-slot="{navigate}" class="btn btn-primary">
                <button @click="navigate" @keypress.enter="navigate" role="link">Nieuwe licentie toevoegen</button>
            </router-link>
            <table class="table buttoned-table">
                <thead>
                <tr>
                    <th scope="col">Code</th>
                    <th scope="col">Resterend/Totaal</th>
                    <th scope="col">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in displayedLicenses">
                    <td>{{ item.Code }}</td>
                    <td :class="{ 'text-warning': item.Uses <= 0 }">{{ item.Uses }} / {{ item.UsesTotal }}</td>
                    <td class="button-column">
                        <router-link :to="`/companies/${companyId}/licenses/${item.id}`" class="btn btn-link">Bewerken</router-link>
                    </td>
                </tr>
                </tbody>
            </table>
        </section>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {WithDocumentId} from "@/types/types";
import {getCompanyCodeMappings} from "@/service/dbService";
import {CompanyCodeMapping} from "@functions/shared/types";

@Component({
    name: "CompanyLicenseListPage"
})
export default class CompanyLicenseListPage extends Vue {
    displayedLicenses: WithDocumentId<CompanyCodeMapping>[] = [];
    isLoading: boolean = false;

    @Prop({type: String})
    companyId!: string;

    async mounted() {
        await this.refresh();
    }

    async refresh(): Promise<void> {
        this.isLoading = true;
        this.displayedLicenses = await getCompanyCodeMappings(this.companyId !== "" ? this.companyId : null);
        this.isLoading = false;
    }
}
</script>

<style lang="scss" scoped>
</style>
