var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _vm.loginNotice !== null
      ? _c("div", { staticClass: "login-notice" }, [
          _vm._v("\n        " + _vm._s(_vm.loginNotice) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "login-form" }, [
      _c("p", [
        _vm._v("Inloggen bij database "),
        _c("strong", [_vm._v(_vm._s(_vm.displayedFriendlyName))]),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.startLogin()
            },
          },
        },
        [
          _c("div", { staticClass: "form-item mb-3" }, [
            _c("label", { attrs: { for: "form-email-field" } }, [
              _vm._v("Email"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "email", name: "email", id: "form-email-field" },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-item mb-3" }, [
            _c("label", { attrs: { for: "form-password-field" } }, [
              _vm._v("Wachtwoord"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                name: "password",
                id: "form-password-field",
              },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-item mb-3" }, [
      _c("input", {
        attrs: { type: "submit", name: "login", value: "Inloggen" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }