<template>
    <div class="custom-notification-page">
        <p class="alert alert-warning" v-if="error !== null">{{ error }}</p>
        <p>Code test page.</p>
        <p v-if="sending" style="background: greenyellow">Sending</p>
        <div class="form-item mb-3">
            <label for="userCode">Code</label>
            <input name="user_code" id="userCode" class="form-control" v-model="userCode">
        </div>
        <div class="form-item mb-3">
            <button v-on:click="executeValidateCodeCorrect()" :disabled="sendButtonBlocked">TestCode</button>
            <button v-on:click="executeUseCode()" :disabled="sendButtonBlocked">UseCode</button>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {useCode, validateCodeCorrect} from "@/service/functionService";

@Component({
    name: "TestCodePage"
})
export default class TestCodePage extends Vue {
    sendButtonBlocked: boolean = false;
    sending: boolean = false;
    userCode: string = "";
    error: string | null = null;

    async runner(runWith: Function) {
        if (this.sendButtonBlocked) {
            alert('Al bezig met versturen.');
            return;
        }
        if (!this.userCode) {
            alert('Je moet een titel, topic en text ingeven.');
            return;
        }

        //this.sendButtonBlocked = true;

        this.sending = true;
        try {
            let response = await runWith({
                code: this.userCode,
            });
            this.error = response.message;
        } catch (e) {
            console.error("Caught %o", e);
            this.error = e.toString();
        }
        this.sending = false;
    }

    async executeValidateCodeCorrect() {
        return await this.runner(validateCodeCorrect);
    }

    async executeUseCode() {
        return await this.runner(useCode);
    }
}
</script>
