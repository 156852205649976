<template>
    <div class="claims-page">
        <p class="alert alert-warning" v-if="error !== null">{{ error }}</p>
        <p v-if="sending">Activating</p>
        <p>Dit is een debug pagina om de user claims handmatig aan te kunnen passen.</p>
        <p>Gebruik de Firebase Console om de gebruiker aan te maken. Daarna kun je dit formulier gebruiken om
            administrator rechten toe te kennen.</p>
        <div class="form-item mb-3">
            <label for="manageUserClaimsUid">Email of UID</label>
            <input type="text" name="manage_user_claims_uid" id="manageUserClaimsUid" class="form-control"
                   v-model="userUid">
        </div>
        <div class="form-item mb-3">
            <label for="manageUserClaimsAction">Action</label>

            <select type="text" name="manage_user_claims_action" id="manageUserClaimsAction" class="form-control"
                    v-model="action">
                <option value="GET_CLAIMS">Get Claims</option>
                <option value="SET_CLAIMS">Set Claims To</option>
                <option value="UPDATE_CLAIMS">Update Specified Claims</option>
                <option value="REMOVE_CLAIMS">Remove Specified Claims</option>
                <option value="GIVE_ADMIN_RIGHTS">Give Admin Rights</option>
            </select>
        </div>
        <div class="form-item mb-3">
            <label for="manageUserClaimsTextarea">Textarea</label>

            <textarea name="manageUserClaimsTextarea" id="manageUserClaimsTextarea" v-model="textarea" cols="30"
                      class="form-control" rows="5" style="font-family: monospace"></textarea>
        </div>
        <div class="form-item mb-3">
            <button v-on:click="manageUserClaimsActivate()" :disabled="sendButtonBlocked">Call Function</button>
        </div>
        <div class="form-item mb-3">
            <label for="manageUserClaimsTextarea">Resultaat</label>

            <textarea name="manageUserClaimsTextarea" id="manageUserClaimsResultArea" v-model="resultArea" cols="30"
                      class="form-control disabled" rows="5" style="font-family: monospace" readonly></textarea>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {manageUserClaims} from "@/service/functionService";
import {ManageUserClaimsData} from "@functions/shared/types";
import {manageUserClaimsValidActions} from "@functions/shared/values";

@Component({
    name: "ManageUserClaimsPage"
})
export default class ManageUserClaimsPage extends Vue {
    sendButtonBlocked: boolean = false;
    sending: boolean = false;
    userUid: string = "";
    action: string = "GET_CLAIMS";
    textarea: string = "";
    resultArea: string = "";
    error: string | null = null;

    async manageUserClaimsActivate() {
        let claimsValue: undefined | { [key: string]: any } = undefined;
        try {
            claimsValue = this.parseValidateTextarea();
        } catch (e) {
            this.error = e;
            return;
        }
        this.sendButtonBlocked = true;

        this.sending = true;
        try {
            let response = await manageUserClaims({
                UserID: this.userUid,
                UserIDIsEmail: this.userUid.indexOf("@") !== -1,
                Action: ManageUserClaimsPage.parseAction(this.action),
                ClaimsToEdit: claimsValue,
            });
            this.resultArea = JSON.stringify(response, null, 4);
        } catch (e) {
            console.info("Caught %o", e);
            this.error = e.toString();
        }
        this.sendButtonBlocked = false;
        this.sending = false;
    }

    parseValidateTextarea() {
        if (this.textarea === "") {
            return;
        }
        return JSON.parse(this.textarea);
    }

    private static parseAction(action: string): ManageUserClaimsData["Action"] {
        if (manageUserClaimsValidActions.indexOf(action as ManageUserClaimsData["Action"]) !== -1) {
            return action as ManageUserClaimsData["Action"];
        }
    }
}
</script>
