import FirebaseOptions from "firebase/app";
import {FirebaseOptionsMeta} from "@/types/types";

export const firebaseConfig: FirebaseOptions.FirebaseOptions = {
    apiKey: "AIzaSyBfb7ccH-jslmekiu9OMPq8b-NFR-q0NXI",
    authDomain: "tech-team-binqi.firebaseapp.com",
    projectId: "tech-team-binqi",
    storageBucket: "tech-team-binqi.appspot.com",
    messagingSenderId: "697997722380",
    appId: "1:697997722380:web:5a331caebedd45b0e87f29",
    measurementId: "G-Z21PNCM1J5",
};

// This has to be located in the same file for it to be effective. These are extra settings not passed to Firebase.
// Instead, the friendlyName is used to display which Firebase DB it is, and the functionsRegion is passed to Firebase
// outside of the main configuration system (which is just how it works).
export const firebaseMeta: FirebaseOptionsMeta = {
    friendlyName: "tech-team-binqi-prod",
    functionsRegion: "europe-west3",
};
