<template>
    <div class="login-page">
        <div class="login-notice" v-if="loginNotice !== null">
            {{ loginNotice }}
        </div>
        <div class="login-form">
            <p>Inloggen bij database <strong>{{ displayedFriendlyName }}</strong></p>
            <form action="" v-on:submit.prevent="startLogin()">
                <div class="form-item mb-3">
                    <label for="form-email-field">Email</label>
                    <input class="form-control" type="email" name="email" id="form-email-field" v-model="email">
                </div>
                <div class="form-item mb-3">
                    <label for="form-password-field">Wachtwoord</label>
                    <input class="form-control" type="password" name="password" id="form-password-field" v-model="password">
                </div>
                <div class="form-item mb-3">
                    <input type="submit" name="login" value="Inloggen">
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {firebaseAuth} from "@/globals";

import {FirebaseError} from '@firebase/util';
import * as auth from "firebase/auth";
import {getLoginState, logOutCurrentUser} from "@/service/loginService";

import {firebaseMeta} from "../../config/firebaseconfig.COND_FIREBASE";

const AFTER_LOGIN_PAGE = "/";

@Component({
    name: "LoginPage"
})
export default class LoginPage extends Vue {
    loginNotice: string|null = null
    email: string = ""
    password: string = ""
    working: boolean = false;
    displayedFriendlyName: string = "";

    @Prop({type: Boolean})
    loggingOut!: boolean;

    async mounted() {
        this.displayedFriendlyName = firebaseMeta.friendlyName;

        this.working = true;
        if(this.loggingOut) {
            await logOutCurrentUser();
            this.loginNotice = "Uitgelogd!";
        }
        let st = await getLoginState();
        this.working = false;

        if (st.loggedIn) {
            await this.$router.replace(AFTER_LOGIN_PAGE);
        }
    }

    async startLogin() {
        console.info("Initiating login...");
        this.working = true;
        try {
            let loginResult = await auth.signInWithEmailAndPassword(firebaseAuth, this.email, this.password);

            if (loginResult && loginResult.user) {
                this.$emit("AuthenticationCompleted", loginResult.user);
                await this.$router.replace(AFTER_LOGIN_PAGE);
            }
        } catch (e) {
            if (e instanceof FirebaseError) {
                this.loginNotice = e.code;
            }
        }
        finally {
            this.working = false;
        }

    }
}
</script>

<style lang="scss" scoped>
</style>
