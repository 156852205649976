var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "companies-page" }, [
    _c(
      "section",
      [
        _c("h3", [_vm._v("Bedrijven")]),
        _vm._v(" "),
        _c("router-link", {
          staticClass: "btn btn-primary",
          attrs: { to: "/companies/new", custom: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var navigate = ref.navigate
                return [
                  _c(
                    "button",
                    {
                      attrs: { role: "link" },
                      on: {
                        click: navigate,
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return navigate.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Nieuw bedrijf toevoegen")]
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c("table", { staticClass: "table table-sm buttoned-table" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.displayedCompanies, function (item) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(item.Name))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "button-column" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-link",
                        attrs: { to: "/companies/" + item.id + "/edit" },
                      },
                      [_vm._v("Bewerken")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-link",
                        attrs: { to: "/companies/" + item.id + "/licenses" },
                      },
                      [_vm._v("Licenties")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-link",
                        attrs: { to: "/companies/" + item.id + "/users" },
                      },
                      [_vm._v("Gebruikers")]
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Naam")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }