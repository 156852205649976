var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-license-delete-page" }, [
    _c("section", [
      _c("h3", [_vm._v("Licentie verwijderen")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Vanaf deze pagina is het mogelijk om een licentie te verwijderen. Let op: na het verwijderen van de licentie zullen alle gebruikers die via deze code toegangg hebben gekregen hun toegang direct verliezen."
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.isLoading
          ? _c("div", [_vm._m(0)])
          : _vm.isDeleting
          ? _c("div", [_vm._m(1)])
          : _c("div", [
              _c(
                "form",
                {
                  staticStyle: { "margin-top": "30px" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.confirmDeleteCompanyLicense.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-item mb-3" }, [
                    _c("label", { attrs: { for: "companyNumUsersEl" } }, [
                      _vm._v("Aantal gebruikers"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fetchedUserMessage,
                          expression: "fetchedUserMessage",
                        },
                      ],
                      staticClass: "form-control disabled",
                      attrs: {
                        id: "companyNumUsersEl",
                        type: "text",
                        name: "num_users",
                        readonly: "",
                      },
                      domProps: { value: _vm.fetchedUserMessage },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.fetchedUserMessage = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-item form-check mb-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.licenseAllowDelete,
                          expression: "licenseAllowDelete",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        id: "companyAllowDelete",
                        value: "1",
                        name: "allow_delete",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.licenseAllowDelete)
                          ? _vm._i(_vm.licenseAllowDelete, "1") > -1
                          : _vm.licenseAllowDelete,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.licenseAllowDelete,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "1",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.licenseAllowDelete = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.licenseAllowDelete = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.licenseAllowDelete = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "companyAllowDelete" },
                      },
                      [_vm._v("Bevestig verwijderen")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: {
                        type: "submit",
                        disabled: !_vm.licenseAllowDelete,
                      },
                    },
                    [_vm._v("Bevestig verwijderen")]
                  ),
                ]
              ),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "spinner-border", attrs: { role: "status" } }),
      _vm._v(
        "\n                    Gegevens worden opgehaald.\n                "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "spinner-border", attrs: { role: "status" } }),
      _vm._v(
        "\n                    Gegevens worden verwijderd.\n                "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }