// Add Bootstrap styling
import "../web/external.scss";

// Add our styling
import "../web/style.scss";

// Needed polyfill
import "regenerator-runtime/runtime";

// Prepare the global stuff (Firebase)
import "./globals";

// Set up Vue
import Vue from 'vue'
Vue.config.devtools = true

Vue.prototype.log = console.log

// Set up Vue Router for our app
import VueRouter from "vue-router";
Vue.use(VueRouter)

// Set up VueRouter + vue-class-component
import Component from 'vue-class-component'

// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
])
