import * as functions from "firebase/functions";
import {firebaseFunctions} from "@/globals";
import type {
    DetachLicenseData,
    FunctionSuccessMessage,
    ManageUserClaimsData,
    ManageUserClaimsSuccess,
    UseCodeData,
    ValidateCodeCorrectData
} from "@functions/shared/types";

// Returns a promise that rejects to FunctionErrorMessage
export async function manageUserClaims(data: ManageUserClaimsData): Promise<ManageUserClaimsSuccess> {
    return new Promise((async (resolve, reject) => {
        let ref = functions.httpsCallable(firebaseFunctions, "manageUserClaims");
        try {
            let result = await ref(data);
            resolve(result.data as ManageUserClaimsSuccess);
        }
        catch(e) {
            reject(e);
        }
    }));
}

// Returns a promise that rejects to FunctionErrorMessage
export async function validateCodeCorrect(data: ValidateCodeCorrectData): Promise<FunctionSuccessMessage> {
    return new Promise((async (resolve, reject) => {
        let ref = functions.httpsCallable(firebaseFunctions, "validateCodeCorrect");
        try {
            let result = await ref(data);
            resolve(result.data as FunctionSuccessMessage);
        }
        catch(e) {
            reject(e);
        }
    }));
}

// Returns a promise that rejects to FunctionErrorMessage
export async function useCode(data: UseCodeData): Promise<FunctionSuccessMessage> {
    return new Promise((async (resolve, reject) => {
        let ref = functions.httpsCallable(firebaseFunctions, "useCode");
        try {
            let result = await ref(data);
            resolve(result.data as FunctionSuccessMessage);
        }
        catch(e) {
            reject(e);
        }
    }));
}

// Returns a promise that rejects to FunctionErrorMessage
export async function detachLicense(data: DetachLicenseData): Promise<FunctionSuccessMessage> {
    return new Promise((async (resolve, reject) => {
        let ref = functions.httpsCallable(firebaseFunctions, "detachLicense");
        try {
            let result = await ref(data);
            resolve(result.data as FunctionSuccessMessage);
        }
        catch(e) {
            reject(e);
        }
    }));
}
