<template>
    <div class="company-edit-page">
        <section>
            <h3 v-if="isNewCompany">Bedrijf toevoegen</h3>
            <h3 v-else>Bedrijf bewerken</h3>
            <div>
                <form v-on:submit.prevent="saveCompany">
                    <div class="form-item mb-3">
                        <label for="companyEditNameEl">Titel</label>
                        <input id="companyEditNameEl" v-model="companyInfo.Name" type="text" name="company_name"
                               class="form-control">
                    </div>
                    <div class="buttons">
                        <button type="submit" v-on:click.prevent="saveCompany" class="btn btn-primary">
                            Opslaan
                        </button>
                    </div>
                </form>

                <form v-on:submit.prevent="deleteCompany" v-if="!isNewCompany" style="margin-top: 30px">
                    <button type="submit" class="btn btn-danger">Verwijder bedrijf</button>
                </form>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Company} from "@/types/types";
import {getCompany, makeValidCompany, writeCompany} from "@/service/dbService";

@Component({
    name: "CompanyEditPage"
})
export default class CompanyEditPage extends Vue {
    companyInfo: Partial<Company> = {};
    isLoading: boolean = false;

    @Prop({type: Boolean})
    isNewCompany!: boolean;

    @Prop({type: String})
    companyId!: string;

    async mounted() {
        await this.refresh();
    }

    async refresh(): Promise<void> {
        if (this.isNewCompany) {
            this.setupNewCompany();
            return;
        }

        this.isLoading = true;

        let companyDoc = await getCompany(this.companyId);
        if (!companyDoc) {
            throw new Error("Failed to retrieve company data.");
        }

        this.companyInfo = companyDoc;
        this.isLoading = false;
    }

    setupNewCompany() {
        this.companyInfo = {
            Name: ""
        };
    }

    async saveCompany() {
        await writeCompany(this.companyId !== "" ? this.companyId : null, makeValidCompany(this.companyInfo));
        await this.$router.replace('/companies');
    }

    async deleteCompany() {
        await this.$router.replace(`/companies/${this.companyId}/delete`);
    }
}
</script>

<style lang="scss" scoped>
</style>
