import * as fs from "firebase/firestore";
import {firestore} from "@/globals";
import {Company, CompanyUserList, UserData, WithDocumentId} from "@/types/types";
import {CompanyCodeMapping} from "@functions/shared/types";

function cloneWithoutIdField<T extends {}>(data: T): Omit<T, 'id'> {
    if ('id' in data) {
        // TypeScript doesn't want to believe it *might* have an id field.
        // @ts-ignore
        let {id, ...dataClone} = data;
        return dataClone;
    } else {
        let {...dataClone} = data;
        return dataClone;
    }
}

function addFirebaseId<T>(doc: T, firebaseId: string|null): WithDocumentId<T> {
    return {
        ...doc,
        id: firebaseId
    };
}

export async function getCompanyCodeMapping(licenseId: string): Promise<WithDocumentId<CompanyCodeMapping> | null> {
    return new Promise(async (resolve) => {
        const itemDoc = await fs.getDoc(fs.doc(firestore, `CompanyCodeMapping`, licenseId));
        const itemData = await itemDoc.data();
        if (!itemData) {
            resolve(null);
            return;
        }

        resolve(makeValidCompanyCodeMapping(itemData, itemDoc.id));
    });
}

export async function getCompanyCodeMappings(filterToCompany: null|string = null): Promise<WithDocumentId<CompanyCodeMapping>[]> {
    return new Promise(async (resolve) => {
        let collection = fs.collection(firestore, `CompanyCodeMapping`);

        // Prep query if needed
        let query: fs.Query<fs.DocumentData> | null = null;
        if (filterToCompany !== null) {
            query = fs.query(collection, fs.where("CompanyID", "==", filterToCompany));
        }

        const itemData = await fs.getDocs(query !== null ? query : collection);
        let itemList: WithDocumentId<CompanyCodeMapping>[] = [];
        itemData.docs.forEach(firebaseDoc => {
            let firebaseData = firebaseDoc.data();
            itemList.push(makeValidCompanyCodeMapping(firebaseData, firebaseDoc.id));
        });

        resolve(itemList);
    });
}

export async function getCompany(companyId: string): Promise<WithDocumentId<Company> | null> {
    return new Promise(async (resolve) => {
        const itemDoc = await fs.getDoc(fs.doc(firestore, `Companies`, companyId));
        const itemData = await itemDoc.data();
        if (!itemData) {
            resolve(null);
            return;
        }

        resolve(makeValidCompany(itemData, itemDoc.id));
    });
}

export async function getAllCompanies(): Promise<WithDocumentId<Company>[]> {
    return new Promise(async (resolve) => {
        const itemData = await fs.getDocs(fs.collection(firestore, `Companies`));
        let itemList: WithDocumentId<Company>[] = [];
        itemData.docs.forEach(firebaseDoc => {
            let firebaseData = firebaseDoc.data();
            itemList.push(makeValidCompany(firebaseData, firebaseDoc.id));
        });

        resolve(itemList);
    });
}

export async function getCompanyUserList(companyId: string): Promise<WithDocumentId<CompanyUserList>[]> {
    return new Promise(async (resolve) => {
        const itemData = await fs.getDocs(fs.collection(firestore, `Companies/${companyId}/UserList`));
        let itemList: WithDocumentId<CompanyUserList>[] = [];
        itemData.docs.forEach(firebaseDoc => {
            let firebaseData = firebaseDoc.data();
            itemList.push(makeValidCompanyUserList(firebaseData, firebaseDoc.id));
        });

        resolve(itemList);
    });
}

export async function getUserData(userId: string): Promise<WithDocumentId<UserData> | null> {
    return new Promise(async (resolve) => {
        const itemDoc = await fs.getDoc(fs.doc(firestore, `UserData`, userId));
        const itemData = await itemDoc.data();
        if (!itemData) {
            resolve(null);
            return;
        }

        resolve(makeValidUserData(itemData, itemDoc.id));
    });
}

// Convert the given Date-ish to a Firestore timestamp. If the ts specified is a number, it should be in milliseconds.
function makeFirestoreTimestamp(ts: Date | fs.Timestamp | number | unknown): fs.Timestamp|null {
    if (ts instanceof fs.Timestamp) {
        return ts;
    }
    else if(ts instanceof Date) {
        return fs.Timestamp.fromDate(ts);
    }
    else if(typeof ts === "number") {
        return fs.Timestamp.fromMillis(ts);
    }
    else {
        return null;
    }
}

// Convert the given Date-ish to a time in milliseconds.
function makeMillis(ts: Date | fs.Timestamp | number | unknown): number|null {
    if (ts instanceof fs.Timestamp) {
        return ts.toMillis();
    }
    else if(ts instanceof Date) {
        return ts.getTime();
    }
    else if(typeof ts === "number") {
        return ts;
    }
    else {
        return null;
    }
}

export function makeValidCompany(doc: fs.DocumentData | Partial<Company>, id: string|null = null): WithDocumentId<Company> {
    return addFirebaseId({
        Name: doc.Name || "",
    }, id);
}

export function makeValidCompanyCodeMapping(doc: fs.DocumentData | Partial<CompanyCodeMapping>, id: string|null = null): WithDocumentId<CompanyCodeMapping> {
    let newDoc: WithDocumentId<CompanyCodeMapping> = addFirebaseId({
        CompanyID: doc.CompanyID || "",
        Code: doc.Code || "",
        CodeUpper: doc.CodeUpper || doc.Code.toUpperCase() || "",
        Uses: parseInt(doc.Uses, 10) || 0,
        // Field added later on. Default to Uses, or 0 as fallback.
        UsesTotal: ('UsesTotal' in doc ? parseInt(doc.UsesTotal, 10) : (parseInt(doc.Uses) || 0)),
        UsageIsTimeLimited: doc.UsageIsTimeLimited || false,
        RegistrationIsTimeLimited: doc.RegistrationIsTimeLimited || false,
    }, id);

    // Timestamp fields
    if ('UsageValidUntil' in doc) {
        let ts = makeMillis(doc.UsageValidUntil);
        if (ts !== null) {
            newDoc.UsageValidUntil = ts;
        }
    }
    if ('RegistrationValidUntil' in doc) {
        let ts = makeMillis(doc.RegistrationValidUntil);
        if (ts !== null) {
            newDoc.RegistrationValidUntil = ts;
        }
    }

    return newDoc;
}

// This UserId/CodeId fallback is a fixup for bad legacy data
export function makeValidCompanyUserList(doc: fs.DocumentData | Partial<CompanyUserList & {CodeId?: string, UserId?: string}>, id: string|null = null): WithDocumentId<CompanyUserList> {
    return addFirebaseId({
        UserID: doc.UserID || doc.UserId,
        CodeID: doc.CodeID || doc.CodeId,
    }, id);
}
export function makeValidUserData(doc: fs.DocumentData | Partial<UserData>, id: string|null = null): WithDocumentId<UserData> {
    let fbDoc: UserData = {
        Username: doc.Username || "",
        CreatedOn: doc.CreatedOn || 0,
        LastPlayedOn: doc.LastPlayedOn || 0,
        PlayedCount: doc.PlayedCount || 0,
    };
    if (doc.Email) {
        fbDoc.Email = doc.Email;
    }
    return addFirebaseId(fbDoc, id);
}

// Writes a company to the database. Leave licenseId null to add.
// Returns the written document ID.
export async function writeCompanyCodeMapping(licenseId: string|null, itemData: CompanyCodeMapping|WithDocumentId<CompanyCodeMapping>): Promise<string> {
    // Remove the document ID, since we don't want to save that
    let itemClone: {[key: string]: any} = {...itemData};
    if ('id' in itemClone) {
        delete itemClone.id;
    }

    // Convert timestamps to fs.Timestamp
    if ('UsageValidUntil' in itemData) {
        let ts = makeFirestoreTimestamp(itemData.UsageValidUntil);
        if (ts !== null) {
            itemClone.UsageValidUntil = ts;
        }
    }
    if ('RegistrationValidUntil' in itemData) {
        let ts = makeFirestoreTimestamp(itemData.RegistrationValidUntil);
        if (ts !== null) {
            itemClone.RegistrationValidUntil = ts;
        }
    }

    return new Promise(async (resolve) => {
        if (licenseId !== null) {
            const itemRef = fs.doc(firestore, `CompanyCodeMapping/${licenseId}`);
            await fs.setDoc(itemRef, itemClone);
        } else {
            const collection = fs.collection(firestore, "CompanyCodeMapping");
            const itemRef = await fs.addDoc(collection, itemClone);
            licenseId = itemRef.id;
        }

        resolve(licenseId);
    });
}


// Writes a company to the database. Leave companyId null to add.
// Returns the written document ID.
export async function writeCompany(companyId: string|null, itemData: Company|WithDocumentId<Company>): Promise<string> {
    // Remove the document ID, since we don't want to save that
    let itemClone = {...itemData};
    if ('id' in itemClone) {
        delete itemClone.id;
    }

    return new Promise(async (resolve) => {
        if (companyId !== null) {
            const itemRef = fs.doc(firestore, `Companies/${companyId}`);
            await fs.setDoc(itemRef, itemClone);
        } else {
            const collection = fs.collection(firestore, "Companies");
            const itemRef = await fs.addDoc(collection, itemClone);
            companyId = itemRef.id;
        }

        resolve(companyId);
    });
}

// Delete a company. This will NOT delete the UserList.
export async function deleteCompany(companyId: string) {
    await fs.deleteDoc(fs.doc(firestore, `Companies/${companyId}`));
}

// Delete a company code mapping (license) by its ID.
export async function deleteCompanyCodeMapping(mappingId: string) {
    await fs.deleteDoc(fs.doc(firestore, `CompanyCodeMapping/${mappingId}`));
}

// Delete one or more company code mappings (license) by its company ID.
export async function deleteCompanyCodeMappingByCompanyId(companyId: string) {
    let query = fs.query(fs.collection(firestore, "CompanyCodeMapping"), fs.where("CompanyID", "==", companyId));
    let docs = await fs.getDocs(query);
    let batch = fs.writeBatch(firestore);
    for (let i = 0; i < docs.docs.length; i++) {
        batch.delete(docs.docs[i].ref);
    }
    await batch.commit();
}
