var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-notification-page" }, [
    _vm.error !== null
      ? _c("p", { staticClass: "alert alert-warning" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("p", [_vm._v("Code test page.")]),
    _vm._v(" "),
    _vm.sending
      ? _c("p", { staticStyle: { background: "greenyellow" } }, [
          _vm._v("Sending"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-item mb-3" }, [
      _c("label", { attrs: { for: "userCode" } }, [_vm._v("Code")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.userCode,
            expression: "userCode",
          },
        ],
        staticClass: "form-control",
        attrs: { name: "user_code", id: "userCode" },
        domProps: { value: _vm.userCode },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.userCode = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-item mb-3" }, [
      _c(
        "button",
        {
          attrs: { disabled: _vm.sendButtonBlocked },
          on: {
            click: function ($event) {
              return _vm.executeValidateCodeCorrect()
            },
          },
        },
        [_vm._v("TestCode")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { disabled: _vm.sendButtonBlocked },
          on: {
            click: function ($event) {
              return _vm.executeUseCode()
            },
          },
        },
        [_vm._v("UseCode")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }