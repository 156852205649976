import VueRouter from "vue-router";
import HomePage from "@/general/components/HomePage.vue";
import LoginPage from "../template-lib/components/LoginPage.vue";
import NotFoundPage from "../template-lib/components/NotFoundPage.vue";
import {getKnownLoginState, getLoginState} from "@/service/loginService";
import HelpPage from "@/general/components/HelpPage.vue";
import TestCodePage from "@/general/components/TestCodePage.vue";

import CompaniesListPage from "@/general/pages/CompanyListPage.vue";
import CompanyEditPage from "@/general/pages/CompanyEditPage.vue";
import CompanyDeletePage from "@/general/pages/CompanyDeletePage.vue";
import CompanyLicenseListPage from "@/general/pages/CompanyLicenseListPage.vue";
import CompanyUserListPage from "@/general/pages/CompanyUserListPage.vue";
import CompanyLicenseEditPage from "@/general/pages/CompanyLicenseEditPage.vue";
import CompanyLicenseDeletePage from "@/general/pages/CompanyLicenseDeletePage.vue";
import ManageUserClaimsPage from "../template-lib/components/ManageUserClaimsPage.vue";

export const router = new VueRouter({
    routes: [
        {path: "/", component: HomePage, props: true},
        {path: "/help", component: HelpPage, props: true},
        {path: "/login", component: LoginPage, props: {loggingOut: false}, meta: {allowWithoutLogin: true}},
        {path: "/logout", component: LoginPage, props: {loggingOut: true}, meta: {allowWithoutLogin: true}},

        {path: "/companies", component: CompaniesListPage, props: true},
        {path: "/companies/new", component: CompanyEditPage, props: (r) => {return {isNewCompany: true, companyId: ""}}},

        {path: "/companies/:companyId/edit", component: CompanyEditPage, props: true},
        {path: "/companies/:companyId/delete", component: CompanyDeletePage, props: true},
        {path: "/companies/:companyId/licenses", component: CompanyLicenseListPage, props: true},
        {path: "/companies/:companyId/users", component: CompanyUserListPage, props: true},

        {path: "/companies/:companyId/licenses/new", component: CompanyLicenseEditPage, props: (r) => {return {isNewLicense: true, companyId: r.params.companyId}}},
        {path: "/companies/:companyId/licenses/:licenseId", component: CompanyLicenseEditPage, props: true},
        {path: "/companies/:companyId/licenses/:licenseId/delete", component: CompanyLicenseDeletePage, props: true},

        //        {path: "/series/:seriesId", component: SeriesEditPage, props: (r) => {return {isNewSeries: false, seriesId: r.params.seriesId}}},
        {path: "/testcode", component: TestCodePage, props: true},
        {path: "/debug/manage-claims", component: ManageUserClaimsPage, props: true},
        {path: '*', component: NotFoundPage}
    ],
    mode: "history",
});

// Data to pass to vue-router navguard next() to send the user to login.
const TO_LOGIN_PAGE = {path: '/login'};

// Login validation
router.beforeEach((to, from, next) => {
    // Always allow pages that work without a login.
    if (to.meta && to.meta.allowWithoutLogin) {
        next();
        return;
    }

    // Get our login state. First try the quick synchronous function.
    let state = getKnownLoginState();
    if (state !== null) {
        // We know for sure.
        if (state.loggedIn) {
            next();
        } else {
            next(TO_LOGIN_PAGE);
        }
        return;
    }

    // We're still here, then. Set up a callback.
    getLoginState().then(ls => {
        console.debug("Obtained login state %o from server", ls);
        if (ls.loggedIn) {
            next();
        } else {
            next(TO_LOGIN_PAGE);
        }
    }).catch(e => {
        next(e as Error);
    })
});
