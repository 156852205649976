<template>
    <div class="license-edit-page">
        <section>
            <h3 v-if="isNewLicense">Licentie toevoegen: {{ companyInfo.Name }}</h3>
            <h3 v-else>Licentie bewerken: {{ companyInfo.Name }}</h3>
            <div>
                <form v-on:submit.prevent="saveLicense">
                    <div class="form-item mb-3">
                        <label for="licenseEditCodeEl">Code</label>
                        <input id="licenseEditCodeEl" v-model="licenseInfo.Code" type="text" name="license_code"
                               class="form-control">
                    </div>
                    <div class="form-item mb-3">
                        <label for="licenseEditUsesEl">Resterend/totaal</label>
                        <div v-if="!isNewLicense" style="display: inline-block">
                            <input id="licenseEditUsesEl" v-model="licenseInfo.Uses" type="number" min="0" name="license_uses"> /
                        </div>
                        <input id="licenseEditUsesTotalEl" v-model="licenseInfo.UsesTotal" type="number" min="0" name="license_usestotal">
                    </div>
                    <div class="form-item mb-3">
                        <label>Geldigheidsduur registratie nieuwe gebruikers</label>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="license_registration_until_type" id="licenseRegistrationUntilTypeLimitedEl" v-model="licenseInfo.RegistrationIsTimeLimited">
                            <label class="form-label-check" for="licenseRegistrationUntilTypeLimitedEl">
                                Registratiebeperking aan. Registratie mogelijk tot en met:
                            </label>
                            <div style="display: inline-block">
                                <input type="date" v-model="registrationTimeLimit" :disabled="!licenseInfo.RegistrationIsTimeLimited">
                            </div>
                        </div>
                    </div>
                    <div class="form-item mb-3">
                        <label>Geldigheidsduur alle gebruikers</label>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="license_usage_until_type" id="licenseUsageUntilTypeLimitedEl" v-model="licenseInfo.UsageIsTimeLimited">
                            <label class="form-label-check" for="licenseUsageUntilTypeLimitedEl">
                                Gebruiksbeperking aan. Gebruik mogelijk tot en met:
                            </label>
                            <div style="display: inline-block">
                                <input type="date" v-model="usageTimeLimit" :disabled="!licenseInfo.UsageIsTimeLimited">
                            </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <button type="submit" v-on:click.prevent="saveLicense" class="btn btn-primary">
                            Opslaan
                        </button>
                    </div>
                    <form v-on:submit.prevent="deleteLicense" v-if="!isNewLicense" style="margin-top: 30px">
                        <button type="submit" class="btn btn-danger">Verwijder licentie</button>
                    </form>
                </form>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Company} from "@/types/types";
import {
    getCompany,
    getCompanyCodeMapping,
    makeValidCompanyCodeMapping,
    writeCompanyCodeMapping
} from "@/service/dbService";
import {CompanyCodeMapping} from "@functions/shared/types";

@Component({
    name: "CompanyLicenseEditPage"
})
export default class CompanyLicenseEditPage extends Vue {
    companyInfo: Partial<Company> = {};
    licenseInfo: Partial<CompanyCodeMapping> = {};
    isLoading: boolean = false;

    @Prop({type: Boolean})
    isNewLicense!: boolean;

    @Prop({type: String})
    companyId!: string;

    @Prop({type: String})
    licenseId!: string;

    usageTimeLimit: string | null = null;
    registrationTimeLimit: string | null = null;

    async mounted() {
        await this.refresh();
    }

    async refresh(): Promise<void> {
        this.isLoading = true;

        let companyDoc = await getCompany(this.companyId);
        if (!companyDoc) {
            throw new Error("Failed to retrieve company data.");
        }

        this.companyInfo = companyDoc;

        this.usageTimeLimit = null;
        this.registrationTimeLimit = null;

        if (this.isNewLicense) {
            this.setupNewLicense();
            this.isLoading = false;
            return;
        }

        let licenseDoc = await getCompanyCodeMapping(this.licenseId);
        if (!licenseDoc) {
            throw new Error("Failed to retrieve license data.");
        }

        console.info("Got License Doc: %o", licenseDoc);

        this.licenseInfo = licenseDoc;

        if (this.licenseInfo.UsageValidUntil && !isNaN(this.licenseInfo.UsageValidUntil)) {
            this.usageTimeLimit = this.dateToYMD(new Date(this.licenseInfo.UsageValidUntil));
            console.log(this.usageTimeLimit);
        }

        if (this.licenseInfo.RegistrationValidUntil && !isNaN(this.licenseInfo.RegistrationValidUntil)) {
            this.registrationTimeLimit = this.dateToYMD(new Date(this.licenseInfo.RegistrationValidUntil));
            console.log(this.registrationTimeLimit);
        }

        this.isLoading = false;
    }

    setupNewLicense() {
        this.licenseInfo = {
            CompanyID: this.companyId,
            Code: this.generateCode(6),
        };
    }

    generateCode(desiredLength: number): string {
        // Valid non-confusable characters
        let strChars = 'B C D F G H J K M P Q R T V W X Y 2 3 4 6 7 8 9'.split(' ');
        let code = '';
        for (let i = 0; i < desiredLength; ++i) {
            code += strChars[Math.floor(Math.random() * strChars.length)];
        }
        return code;
    }

    dateToYMD(date: Date): string {
        let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        let day = date.getUTCDate().toString().padStart(2, '0');
        return `${date.getUTCFullYear()}-${month}-${day}`;
    }

    dateToEndOfDayUTC(ymd: string): number|undefined {
        let ymdSplit = ymd.split("-");
        if (ymdSplit.length !== 3) {
            return undefined;
        }

        let dateStr = `${ymdSplit[0]}-${ymdSplit[1]}-${ymdSplit[2]}T23:59:59Z`;
        return new Date(dateStr).getTime();
    }

    async saveLicense() {
        if (this.isNewLicense) {
            this.licenseInfo.Uses = this.licenseInfo.UsesTotal;
        }

        console.info(this.registrationTimeLimit);
        if (this.usageTimeLimit && this.usageTimeLimit.match(/^\d{4}-\d{2}-\d{2}$/)) {
            this.licenseInfo.UsageValidUntil = this.dateToEndOfDayUTC(this.usageTimeLimit);
        }
        if (this.registrationTimeLimit && this.registrationTimeLimit.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
            this.licenseInfo.RegistrationValidUntil = this.dateToEndOfDayUTC(this.registrationTimeLimit);
        }

        this.licenseInfo.CodeUpper = this.licenseInfo.Code?.toUpperCase();

        await writeCompanyCodeMapping(this.isNewLicense ? null : this.licenseId, makeValidCompanyCodeMapping(this.licenseInfo));
        await this.$router.replace(`/companies/${this.companyId}/licenses`);
    }

    async deleteLicense() {
        await this.$router.replace(`/companies/${this.companyId}/licenses/${this.licenseId}/delete`);
    }
}
</script>

<style lang="scss" scoped>
</style>
