var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-license-list-page" }, [
    _c("section", [
      !_vm.isLoading
        ? _c("h3", [
            _vm._v("Gebruikerslijst voor " + _vm._s(_vm.companyInfo.Name)),
          ])
        : _c("h3", [_vm._v("Gebruikerslijst")]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { disabled: _vm.isLoading },
            on: {
              click: function ($event) {
                return _vm.startCsvExport()
              },
            },
          },
          [_vm._v("Exporteer naar CSV")]
        ),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table table-sm buttoned-table" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Gebruikersnaam")]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [_vm._v("E-mailadres")]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Registratiedatum")]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Laatste speeldatum"),
            ]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Gespeeld")]),
            _vm._v(" "),
            _vm.showLicenses
              ? _c("th", { attrs: { scope: "col" } }, [_vm._v("Licentiecode")])
              : _vm._e(),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [_vm._v(" ")]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.userDataList, function (item) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(item.Username))]),
              _vm._v(" "),
              _c("td", [
                item.Email
                  ? _c("span", [_vm._v(_vm._s(item.Email))])
                  : _c("span", [_c("em", [_vm._v("Geen e-mailadres bekend")])]),
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.formatDate(item.CreatedOn)))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.formatDate(item.LastPlayedOn)))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.PlayedCount))]),
              _vm._v(" "),
              _vm.showLicenses
                ? _c("td", [_vm._v(_vm._s(item.LicenseCode || item.LicenseID))])
                : _vm._e(),
              _vm._v(" "),
              _c("td", { staticClass: "button-column" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    on: {
                      click: function ($event) {
                        return _vm.confirmRemoveLicense(item.UserID)
                      },
                    },
                  },
                  [_vm._v("Verwijder licentie")]
                ),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }