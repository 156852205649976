<template>
    <div id="app" :class="{'fullscreen': this.isFullscreen, 'not-fullscreen': !this.isFullscreen}">
        <div v-if="!this.isFullscreen" class="container-xxl">
            <nav id="menubar" class="navbar" v-if="this.hasMenuBar">
                <div class="container-fluid d-flex">
                    <div class="navbar-brand align-self-start">{{ appBranding.name }}</div>
                    <div class="align-self-end">
                        <ul class="navbar-nav flex-row" v-if="true">
                            <li class="nav-item p-2">
                                <router-link to="/companies" class="nav-link"><i class="bi bi-card-list"></i> Bedrijven
                                </router-link>
                            </li>
                            <li class="nav-item p-2">
                                <router-link to="/logout" class="nav-link">
                                    <i class="bi bi-people"></i> Uitloggen: {{ userDisplayName }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <main>
                <router-view></router-view>

            </main>
        </div>
        <main v-else>
            <router-view></router-view>
        </main>
    </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {getLoginState, LoginState} from "@/service/loginService";
import {appConfig} from "../../../config/config.COND_CONFIG";

@Component({
    name: "App",
    components: {},

    watch: {
        $route(to) {
            // TS thinks this will be ran in the Vue context, but this will be App
            (this as App).isFullscreen = to?.matched[0]?.meta?.isFullscreen ?? false;
            (this as App).hasMenuBar = to?.matched[0]?.meta?.hasMenuBar ?? true;
        }
    },
    provide: function(): {userDisplayName: string|null} {
        return {
            userDisplayName: (this as App).userDisplayName
        };
    }
})
export default class App extends Vue {
    // Controls the menu bar, the padding and some other stuff. If fullscreen, hasMenuBar and the body container is ignored.
    isFullscreen: boolean = false;
    // Controls just the menu bar.
    hasMenuBar: boolean = true;

    loggedIn: boolean = false;

    userAccountInfo: LoginState|null = null;
    userDisplayName: string|null = null;

    readonly appBranding = {
        name: appConfig.brandingName
    };

    readonly config = {
        showNotificationPage: appConfig.showNotificationPage
    };

    async mounted() {
        this.userAccountInfo = await getLoginState();
        this.userDisplayName = this.userAccountInfo?.loginUser?.email || null;
        this.loggedIn = this.userAccountInfo.loggedIn;

        document.title = appConfig.brandingName;
    }
}
</script>

<style lang="scss">
$main-bg: #242422;
$main-color: #f1f1f0;
#menubar {
    background: $main-bg;
    color: $main-color;
    .nav-link {
        color: $main-color;
    }
}
#app.not-fullscreen {
    padding: 10px;
}
</style>
