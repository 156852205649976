var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-edit-page" }, [
    _c("section", [
      _vm.isNewCompany
        ? _c("h3", [_vm._v("Bedrijf toevoegen")])
        : _c("h3", [_vm._v("Bedrijf bewerken")]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.saveCompany.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "form-item mb-3" }, [
              _c("label", { attrs: { for: "companyEditNameEl" } }, [
                _vm._v("Titel"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.companyInfo.Name,
                    expression: "companyInfo.Name",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "companyEditNameEl",
                  type: "text",
                  name: "company_name",
                },
                domProps: { value: _vm.companyInfo.Name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.companyInfo, "Name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveCompany.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        Opslaan\n                    "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        !_vm.isNewCompany
          ? _c(
              "form",
              {
                staticStyle: { "margin-top": "30px" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteCompany.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "button",
                  { staticClass: "btn btn-danger", attrs: { type: "submit" } },
                  [_vm._v("Verwijder bedrijf")]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }