var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-page" }, [
    _c("h1", [_vm._v("Welkom bij de console")]),
    _vm._v(" "),
    _c("p", [_vm._v("Vanaf hier heb je de volgende mogelijkheden:")]),
    _vm._v(" "),
    _c("ul", [
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/companies" } }, [
            _vm._v("Bedrijven"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }