<template>
    <div class="home-page">
        <h1>Welkom bij de console</h1>
        <p>Vanaf hier heb je de volgende mogelijkheden:</p>
        <ul>
            <li>
                <router-link to="/companies">Bedrijven</router-link>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";

@Component({
    name: "HomePage"
})
export default class HomePage extends Vue {
}
</script>
